<template>
  <surround-box :width="330" :title="detailData.name + '养殖场'" :fontSize="14">
    <div class="outer-page">
      <div class="title">
        <!-- <span>{{ detailData.name }}养殖场</span> -->
        <div class="address-info iconfont icon-dingwei">
          <span
            style="
              display: inline-block;
              width: 260px;
              overflow: hidden;
              text-overflow: ellipsis;
              margin-left: 10px;
              white-space: nowrap;
            "
            @mouseenter="
              () => {
                this.addressShow = true;
              }
            "
            @mouseleave="
              () => {
                this.addressShow = false;
              }
            "
          >
            {{ detailData.regionCodeName + detailData.address }}
          </span>
        </div>
        <div class="address-info-hidden" v-show="addressShow">
          {{ detailData.regionCodeName + detailData.address }}
        </div>
      </div>
      <div class="base-info">
        <ul>
          <li>
            <span>{{ data.productNum }}</span
            ><br />
            <span style="font-size: 13px">养殖规模(头)</span>
          </li>
          <li>
            <span>{{ data.area }}</span
            ><br />
            <span style="font-size: 13px">配套土地(亩)</span>
          </li>
          <li>
            <span>{{ data.facilityCount }}</span
            ><br />
            <span style="font-size: 13px">配套设施(个)</span>
          </li>
          <li>
            <span>{{ data.deviceCount }}</span
            ><br />
            <span style="font-size: 13px">配套设备(个)</span>
          </li>
          <li>
            <span>{{ data.production }}</span
            ><br />
            <span style="font-size: 13px">粪污产量(kg/d)</span>
          </li>
          <!-- <li>
            <span>-</span><br />
            <span style="font-size: 13px">配套土壤承载力(kg)</span>
          </li>
          <li>
            <span>-</span><br />
            <span style="font-size: 13px">产能配套率</span>
          </li>
          <li>
            <span>-</span><br />
            <span style="font-size: 13px">待处理(KG)</span>
          </li> -->
          <li v-for="(item, index) in staticsData" :key="index">
            <span>{{ item.content }}</span
            ><br />
            <span style="font-size: 13px">{{
              item.name + "(" + item.unit + ")"
            }}</span>
          </li>
        </ul>
      </div>
    </div>
  </surround-box>
</template>

<script>
import surroundBox from "@/components/mapComp/surroundBox2.vue";
export default {
  name: "",
  components: {
    surroundBox,
  },
  props: {
    currentMapInfo: Object,
  },
  data() {
    return {
      data: [],
      detailData: [],
      addressShow: false,
      staticsData: [],
    };
  },
  methods: {
    getList() {
      this.$post(this.$api.PRODUCE_MAPINFO.STATICS, {
        farmId: this.currentMapInfo ? this.currentMapInfo.sourceId : "",
      }).then((res) => {
        this.data = res;
        console.log(res);
      });
    },
    //养殖场详情
    getFarmDetail() {
      this.$post(this.$api.BASE_MANAGE.INFO, {
        id: this.currentMapInfo ? this.currentMapInfo.sourceId : "",
      }).then((res) => {
        this.detailData = res;
      });
    },
    getStatics() {
      this.$post(this.$api.STATICS_MANAGE.GET4, {
        pid: 8,
      }).then((res) => {
        console.log(res);
        this.staticsData = res;
      });
    },
  },
  watch: {
    currentMapInfo(n) {
      if (n == null) return;
      this.getList();
      this.getFarmDetail();
      this.getStatics();
    },
  },
};
</script>

<style lang="less" scoped>
.outer-page {
  width: 100%;
  height: 100%;
  // background: rgba(23, 34, 60, 0.6);
  // background: rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  color: #fff;
  padding: 16px;
  .title {
    height: 24px;
    line-height: 24px;
    text-align: center;
    font-size: 16px;
    position: relative;
    .address-info {
      display: flex;
      // height: 24px;
      text-align: left;
      font-size: 12px;
      cursor: pointer;
      // margin-top: 6px;
    }
    .address-info-hidden {
      width: 400px;
      position: absolute;
      left: 0;
      top: 30px;
      text-align: left;
      font-size: 14px;
      // color: rgba(0, 0, 0, 0.8);
    }
  }
  .base-info {
    margin-top: 20px;
    height: 370px;
    // border: 1px solid #d8e0f3;
    border-radius: 8px;
    ul {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      li {
        text-align: center;
        width: 50%;
        height: 90px;
        padding-top: 16px;
        span {
          &:nth-child(1) {
            font-size: 26px;
            font-family: PingFang SC;
            color: #d8e0f3;
          }
        }
      }
    }
  }
}
</style>